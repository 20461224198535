<template>
  <v-container class="pt-120">
    <h1>FAQ</h1>
    <h3>
      How long is a With session?
    </h3>
    <p>
      Depends on whoever is hosting the session. It’s up to them, really.
    </p>
    <h3>
      When do I get charged for my With session?
    </h3>
    <p>
      Your card is charged when you book your session.
    </p>
    <h3>
      If I cancel a With session am I eligible for a refund?
    </h3>
    <p>
      Yes. If you need to cancel your session, please contact customer service
      at <a href="mailto:hello@meetwithanyone.com">hello@meetwithanyone.com</a>.
      We value our talents’ time, so if a session is canceled within 24 hours
      you may not be eligible for a full refund.
    </p>
    <h3>
      When do new With sessions become available?
    </h3>
    <p>
      Hosts create new openings when they have room in their schedule. Check
      back frequently and follow With on social media — we announce new
      availability for featured hosts all the time. You can also follow your
      favorite hosts to get notified about when new sessions become available
    </p>
  </v-container>
</template>
<script>
export default {
  name: 'Privacy',
  beforeMount() {
    this.$store.commit('signupModal', false)
  },
}
</script>
